import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 0px;
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--secondary-text);
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 0px 0px 0px rgba(1,74,63,1);
  -webkit-box-shadow: 0px 0px 0px 0px rgba(1,74,63,1);
  -moz-box-shadow: 0px 0px 0px 0px rgba(1,74,63,1);
  :hover {
    background-color: var(--secondary-text);
    border-width: 2px;
    font-weight: normal;
    font-style: oblique;
    color: var(--secondary);
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :active {
    background-color: #000000;
    border-width: 2px;
    font-weight: bold;
    color: var(--secondary);
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--secondary-text);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--secondary);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    background-color: #000000;
    border-width: 2px;
    font-weight: bold;
    color: var(--secondary);
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 500px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const column = styled.div`
float: left;
width: 33.33%;
padding: 5px;
`;

export const row = styled.div`
  content: "";
  clear: both;
  display: table;
`;

export const StyledLogo = styled.img`
  width: 300px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 0%;
  width: 100%;
  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 100%) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary-text);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(``);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `mint successful! you can view your NFT on opensea.io/collection/urbanlines.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 5) {
      newMintAmount = 5;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      
      <s.Container
        flex={1}
        ai={"center"}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/example.png" : null}
        style={{ padding: 5, background: "#ffffff"}}
      >
      <s.SpacerLarge /> 
       <s.Container jc={"center"} ai={"center"} style={{ width: "70%"}}>
          <s.TextTitle
            style={{
              textAlign: "center",
              fontWeight: 'bold',
              fontSize: "40px",
              color: "var(--primary-text)",
            }}
          >
          urban // lines
          </s.TextTitle>
          <s.TextTitle
            style={{
              textAlign: "center",
              fontSize: "30px",
              color: "var(--primary-text)",
            }}
          >
          a generative art project.
          </s.TextTitle>
        </s.Container>
        <s.SpacerMedium />
        
        <ResponsiveWrapper flex={1} style={{ padding: 0 }} test>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              padding: 0,
              borderRadius: 0,
            }}
          >
            <s.TextDescription
              style={{
                textAlign: "center",
                fontSize: "20px",
                color: "var(--accent-text)",
              }}
            >
              {CONFIG.MAX_SUPPLY-data.totalSupply} lines available to mint \\<br></br>
              // mint price: {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL}. 
            </s.TextDescription>
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      connect wallet to mint
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                    <s.SpacerSmall />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "minting..." : "mint"}
                      </StyledButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
            <s.SpacerMedium />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        
        <s.Container
        flex={1}
        ai={"center"}
        style={{padding: 0, background: "#d4d4d4"}}
      >
        <s.SpacerSmall/>
       
        <row align={"left"}>
        <column>
              <img src={"/config/images/72.svg"} style={{width: "22.5%", position:"relative", left:"2%"}}/>
        </column>
        <column>
              <img src={"/config/images/122.svg"} style={{width: "22.5%", position:"relative", left:"4%"}}/>
        </column>
        <column>
              <img src={"/config/images/171.svg"} style={{width: "22.5%", position:"relative", left:"6%"}}/>
        </column>
        <column>
              <img src={"/config/images/77.svg"} style={{width: "22.5%", position:"relative", left:"8%"}}/>
        </column> 
        </row>
        <s.SpacerSmall />
        <row align={"left"}>
        <column>
              <img src={"/config/images/15.svg"} style={{width: "22.5%", position:"relative", left:"2%"}}/>
        </column>
        <column>
              <img src={"/config/images/19.svg"} style={{width: "22.5%", position:"relative", left:"4%"}}/>
        </column>
        <column>
              <img src={"/config/images/26.svg"} style={{width: "22.5%", position:"relative", left:"6%"}}/>
        </column>
        <column>
              <img src={"/config/images/49.svg"} style={{width: "22.5%", position:"relative", left:"8%"}}/>
        </column>
        </row>
        
        
        
        <s.SpacerSmall/>
        </s.Container>

        <s.SpacerLarge />

        <s.Container jc={"left"} ai={"left"} style={{ width: "70%" }}>
          <s.TextTitle
            style={{
              textAlign: "left",
              color: "var(--primary-text)",
              font: "20"
            }}
          >
            What is urban // lines? 
          </s.TextTitle>
          
          <s.TextDescription
            style={{
              textAlign: "justified",
              color: "var(--primary-text)",
            }}
          >
            urban // lines attempts to create variety from simple lines and colors. Three elements interact to make each piece unique - 
            sky (background color), materials (relationship between background and lines in the foreground) and architecture (relationship between different lines). 
            The result is structured but unpredictable, and evokes urban imagery like metro maps, skyscrapers and rush hour streets.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "justified",
              color: "var(--primary-text)",
            }}
          >
            Each NFT artwork can be scaled up to any resolution without pixelatation, and has an unlimited owner license which allows NFT owners to use their pieces
            personally or commercially. We hope you make the art yours: custom print it on a coffee mug, create patterned merchandise based on it, or simply 
            display it in your NFT wallet.
          </s.TextDescription>

          <s.SpacerLarge />
          <s.TextTitle
            style={{
              textAlign: "left",
              color: "var(--primary-text)",
              font: "20"
            }}
          >
            Motivation
          </s.TextTitle>
          
          <s.TextDescription
            style={{
              textAlign: "justified",
              color: "var(--primary-text)",
            }}
          >
            As fans and collectors of NFT art, our main goals are to learn more by doing and contribute to the space in some small way.
            
            In that vein, 50% of each mint is automatically donated to&nbsp; 
            <StyledLink target={"_blank"} style ={{fontWeight: "bold"}}href={'https://www.coincenter.org/about/'}>
              {'Coin Center'}
            </StyledLink>&nbsp;- a non-profit
            educating lawmakers about crypto and advocating for innovation-friendly regulatory clarity.
            
          </s.TextDescription>
          <s.SpacerSmall />  
          <s.TextDescription
            style={{
              textAlign: "justified",
              color: "var(--primary-text)",
            }}
          >
            We also want to contribute to the conversation about NFT owner licenses by opting for an unlimited owner license with this collection. We are bullish 
            on NFT collections that give owners the economic motivation and flexibility to remix and distribute art in their own way (a la <StyledLink target={"_blank"} style ={{fontWeight: "bold"}} href={'https://medium.com/@deconomist/bored-ape-yacht-club-the-case-for-licensed-commercial-use-rights-b1bbd463d189'}>
            {'Bored Ape and derivatives'}</StyledLink>), and hope to see more collections experimenting with this.

          </s.TextDescription>
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.SpacerLarge />
          <s.TextDescription
              style={{
                textAlign: "left",
                fontWeight: "bold",
                color: "var(--primary-text)",
              }}
            >
              Secondary market: <br></br>
              <StyledLink target={"_blank"} href={'https://opensea.io/collection/urbanlines'}>
                {'OpenSea'}
              </StyledLink>
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
              style={{
                textAlign: "left",
                fontWeight: "bold",
                color: "var(--primary-text)",
              }}
            >
              Smart contract: <br></br>
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {CONFIG.CONTRACT_ADDRESS}
              </StyledLink>
          </s.TextDescription>
          
          <s.SpacerLarge/>
          <s.SpacerLarge/>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
